export function validation(val, type) {
    if (type == "phone") {
        let reg = /\d{11}/;
        if (val != "") {
            if (reg.test(val)) {
                return false
            } else {
                return "电话不正确"
            }
        } else {
            return "必填电话"
        }
    }
    if (type == "email") {
        let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (val != "") {
            if (reg.test(val)) {
                return false
            } else {
                return "电邮不正确"
            }
        } else {
            return "必填电邮"
        }

    }
    if (type == "loginName") {
        if (val != "") {
            if (val.length >= 5 && val.length <= 20) {
                return false
            } else {
                return "用户名后缀长度必须在5和20个字之间。"
            }
        } else {
            return "必填帐户名称"
        }

    }
    if (type == "password") {
        // let reg = /(?=.*[a-z_])(?=.*\d)(?=.*[^a-z0-9_])[\S]{8,}/i;
        if (val != "") {
            if (val.length >= 8 && val.length <= 16) {
                // if (reg.test(val)) {
                //     return false
                // } else {
                //     return "密码必须包含大小写字母/数字/符号任意两者组合"
                // }
            } else {
                return "密码长度必须在8和16个字之间。"
            }
        } else {
            return "必填密码"
        }

    }
    if (type == "idCard") {
        console.log("这是身份证")
    }

}