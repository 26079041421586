<template>
  <div class="buyForget">
    <div class="main-card">
      <div class="cell">
        <div class="title">
          <i class="el-icon-back icon" @click="fanhui"></i>
          重新设定密码
        </div>
        <div>
          <el-input
            style="width: 300px"
            v-model.trim="phone"
            placeholder="手机号码"
            @input="handleInput"
            type="number"
          ></el-input>
        </div>
        <div
          v-if="message && message != '必填电话'"
          style="
            color: #ee4d2d;
            font-size: 12px;
            margin-top: 4px;
            text-align: left;
            padding-left: 60px;
          "
        >
          {{ message == "电话不正确" ? "无效的手机号码" : "" }}
        </div>
        <div>
          <el-button
            @click="toPush"
            :disabled="message"
            type="primary"
            style="min-width: 300px; margin-top: 20px"
            >NEXT</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { validation } from "@/utils/formCheck.js";
export default {
  data() {
    return {
      phone: "",
      message: "必填电话",
    };
  },

  methods: {
    fanhui() {
      this.$router.go(-1);
    },
    handleInput() {
      this.message = validation(this.phone, "phone");

    },
    toPush() {
      sessionStorage.SIGNUPPHONE = this.phone;
      this.$router.replace("buyForget2");
    },
  },
};
</script>
<style lang="less" >
.buyForget {
  padding: 100px 0;
  .cell {
    width: 460px;
    background: #fff;
    padding: 20px;
    border-radius: 4px;
    margin: 40px auto 0;
    box-shadow: 0 1px 8px rgba(220, 220, 220, 0.8);
    text-align: center;
    padding-bottom: 50px;
    .title {
      position: relative;
      font-size: 18px;
      margin-bottom: 30px;
      .icon {
        position: absolute;
        left: 0;
        top: 0;
        color: #ee4d2d;
        font-size: 24px;
      }
    }
    .el-input__inner[type="number"]::-webkit-inner-spin-button,
    .el-input__inner[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}
</style>